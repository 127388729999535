<template>
  <div class="home">
    <div class="header-message">
      <h1>{{ $t('Hello,') }} User</h1>
    </div>
    <div class="dashboard">
      <div class="dashboard-cards">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Total Unfulfilled Orders') }}</span>
          </div>
          <div class="card-number">
            {{ unfulfilledOrdersNum }}
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ $t('Total Unpaid Orders') }}</span>
          </div>
          <div class="card-number">
            {{ unpaidOrdersNum }}
          </div>
        </el-card>
      </div>

      <div class="dashboard-charts-wrapper">
        <h2>{{ $t('Data Statistics') }}</h2>
        <TotalSales />
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Order from '@/lib/order';
import TotalSales from '@/components/dashboard/TotalSales.vue';

export default {
  name: 'Home',
  components: {
    TotalSales,
  },
  data(){
    return {
      unfulfilledOrdersNum: 0,
      unpaidOrdersNum: 0,
    };
  },
  mounted(){
    this.loadOrders();
  },
  methods:{
    async loadOrders(){
      try{
        const loginInfo = Common.getLoginInfo();
        const orders = await Order.loadAllOrders(this.apiUrl, loginInfo);
        const unfulfilledOrders = orders.filter(function(order){
          return order['order_status'] == 'Unfulfilled';
        });
        const unpaidOrders = orders.filter(function(order){
          return order['order_status'] == 'Unpaid';
        });
        this.unfulfilledOrdersNum = unfulfilledOrders.length;
        this.unpaidOrdersNum = unpaidOrders.length;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.dashboard{
  margin-top: 30px;
  .dashboard-cards{
    display: flex;
    flex-wrap: wrap;
    .box-card{
      -webkit-box-flex: 0;
      -ms-flex: 0 0 48%;
      flex: 0 0 48%;
      margin-right: 20px;
    }
    .el-card__header{
      text-align: center;
    }
    .card-number{
      text-align: center;
      font-weight: bold;
      font-size: 1.2em;
    }
  }
}
.dashboard-charts-wrapper{
  font-size: 1.5em;
  margin-top: 50px;
}
</style>
